// ** React
import React, { useEffect, useState } from 'react';

// ** MUI Import
import { makeStyles } from '@material-ui/core/styles';

// ** Gatsby
import { graphql } from 'gatsby';

// ** Layout
import Layout from '../containers/layout';

// ** Custom Components
import Container from '../components/layout/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/layout/seo';
import {
  RenderDaysOffered,
  RenderStartTimes,
  campusOptions,
  createURLQueryFromArray,
  days,
  getObjDiff,
  getQueryParametersFromURL,
  times,
} from './search';

// ** Library
import { useForm, useWatch } from 'react-hook-form';

// ** Utils
import { cn, paginate } from '../lib/helpers';

// ** Styles
import { responsiveTitle2 } from '../styles/typography.module.css';
import { Box, Button, Grid } from '@material-ui/core';
import { FormInputDropdown } from '../components/forms/searchForm/formComponents';
import GridCard from '../components/grids/grid-card';
import { courseMap } from '../../gatsbyHelpers';
import FilterForm from '../components/forms/filter-form';
import { Tune } from '@material-ui/icons';
import useDeepCompareEffect from '../lib/deepEqual';

import { termDefault } from '../utils/terms_to_include';


const useStyles = makeStyles((theme) => ({
  courseGrid: {
    '& a, & a p': {
      color: '#252525',
    },
    '& img': {
      objectFit: 'fill !important',
    },
    '& a > div > div:first-child': {
      aspectRatio: 1 / 1,
    },
    '& a > div': {
      // Fix the card issue on mobile
      [theme.breakpoints.down(600)]: {
        display: 'block',
      },
    },
    '& .MuiPagination-ul': {
      flexWrap: 'nowrap',
    },
  },
  titleStyle: {
    textAlign: 'center !important',
    margin: '4rem !important',

    [theme.breakpoints.down('sm')]: {
      margin: '2rem !important',
    },
  },
  dropdownWrapper: {
    marginBottom: '1rem',
  },
  selectWrapper: {
    marginBottom: '1rem',
  },
  mobileFilter: {
    display: 'block',

    [theme.breakpoints.up(900)]: {
      display: 'none',
    },
  },
  desktopFilter: {
    display: 'none',

    [theme.breakpoints.up(900)]: {
      display: 'block',
    },
  },
  filterButton: {
    fontFamily: 'Raleway, -apple-system, BlinkMacSystemFont, sans-serif',
    textTransform: 'capitalize',
    color: 'var(--color-black)',
    maxWidth: '200px',
    fontSize: '1em',
    marginBottom: '1rem',
    border: '1px solid #000',
    width: '100%',
    height: '3.125rem',
    padding: '1rem .5rem',
  },
}));

export const query = graphql`
  query PreCollegeQuery($id: String!, $termsToIncludeArray: [String]) {
    site {
      meta: siteMetadata {
        siteUrl
      }
    }
    page: sanityPreCollege(id: { eq: $id }) {
      title
      slug {
        current
      }
      id
    }
    allCourse: allSanityCourse(
      sort: { order: ASC, fields: courseNumber }
      filter: {
        courseNumber: { regex: "/^AE/i" }
        slug: { current: { ne: "null" } }
        sections: { elemMatch: { term: { in: $termsToIncludeArray } } }
      }
    ) {
      nodes {
        ...Course
      }
    }
    siteSetting: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      fallbackImage {
        _key
        alt
        image {
          asset {
            _id
            url
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

const PreCollegeTemplate = (props) => {
  const { location, pageContext } = props;
  const { courseData } = pageContext;
  const { terms } = courseData;
  const classes = useStyles();
  const {
    data: { page, allCourse, siteSetting },
    errors,
  } = props;
  const mappedAllCourses = allCourse.nodes.map((course) => courseMap(course));

  const termOptions = terms.map((term) => ({ label: term, value: term }));

  const defaultValues = {
    campus: campusOptions.map((campus) => campus.value),
    daysOffered: days.map((day) => day.value),
    searchValue: '',
    term: termDefault,
    timeOffered: times.map((time) => time.value),
  };

  const [showPagination, setShowPagination] = useState(true);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const { title } = page;
  const { fallbackImage } = siteSetting;
  const { search } = location;
  const urlQuery = getQueryParametersFromURL(search, defaultValues);
  const methods = useForm({ defaultValues: urlQuery });
  const { control, watch, handleSubmit } = methods;
  const data = useWatch({ control });
  const [query, setQuery] = useState('');

  useDeepCompareEffect(() => {
    const formDiff = getObjDiff({ term: '' }, data);
    const urlQuery = createURLQueryFromArray(formDiff, '?');
    setQuery(urlQuery);
  }, [data, urlQuery, defaultValues]);

  const filteredCourses = mappedAllCourses
    .filter((course) => {
      if (watch('campus')?.some((campus) => course.campuses.includes(campus))) {
        return course;
      } else if (watch('campus')?.length === campusOptions.length) {
        return course;
      }
    })
    .filter((course) => {
      if (Array.isArray(watch('term'))) {
        return course.terms.some((term) => term === watch('term')[0]);
      } else {
        return course.terms.some((term) => term === watch('term'));
      }
    })
    .filter((course) => {
      if (watch('daysOffered')?.some((day) => course.days.includes(day))) {
        return course;
      } else if (watch('daysOffered')?.length === days.length) {
        return course;
      }
    })
    .filter((course) => {
      if (watch('timeOffered')?.some((time) => course.hours.includes(time))) {
        return course;
      } else if (watch('timeOffered')?.length === times.length) {
        return course;
      }
    });

  useEffect(() => {
    handlePagination(1, filteredCourses);
  }, [filteredCourses.length]);

  let paginationData = paginate(1, filteredCourses.length);
  const [pages, setPages] = useState(paginationData.pages.length);
  const [paginationLocation, setPaginationLocation] = useState([0, paginationData.endIndex]);

  function handlePagination(num, data = filteredCourses) {
    paginationData = paginate(num, data.length);
    setPages(paginationData.pages.length);
    setPaginationLocation([paginationData.startIndex, paginationData.endIndex]);
  }

  const paginatedData = filteredCourses.slice(paginationLocation[0], paginationLocation[1]);

  const handleOnShowAll = () => {
    setShowPagination(false);
  };

  const handleOnMobileFilterClick = () => {
    setShowFilterModal(true);
  };

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {title && <SEO title={title} />}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <Container>
        {title ? <h2 className={cn(responsiveTitle2, classes.titleStyle)}>{title}</h2> : ''}

        <Box className={classes.mobileFilter}>
          <Button
            onClick={handleOnMobileFilterClick}
            className={classes.filterButton}
            endIcon={<Tune fontSize="small" />}
          >
            Filter
          </Button>
        </Box>

        <Box className={classes.desktopFilter}>
          <Box className={classes.filterContainer}>
            <Grid classes={classes.dropdownWrapper} container direction="row" spacing={2}>
              <Grid item>
                <FormInputDropdown control={control} label="Campuses" multiple name="campus" options={campusOptions} />
              </Grid>
              <Grid item>
                <FormInputDropdown control={control} label="Terms" name="term" options={termOptions} />
              </Grid>
            </Grid>
            <Grid className={classes.selectWrapper} container direction="row" spacing={2}>
              <Grid item>
                <RenderDaysOffered control={control} />
              </Grid>
              <Grid item>
                <RenderStartTimes control={control} />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <div className={classes.courseGrid}>
          <GridCard
            data={showPagination ? paginatedData : filteredCourses}
            fallbackImage={fallbackImage}
            showPagination={showPagination}
            pages={pages}
            handlePagination={handlePagination}
            handleOnShowAll={handleOnShowAll}
            loadToScroll
            query={query}
          />
        </div>
      </Container>

      {showFilterModal && (
        <FilterForm
          hideLocation
          hideSchool
          hideDegree
          defaultValues={defaultValues}
          control={control}
          handleSubmit={handleSubmit}
          hideSearchDrawer={() => setShowFilterModal(false)}
        />
      )}
    </Layout>
  );
};

export default PreCollegeTemplate;
